import React from "react"
import * as CS from "../../styles/components/_who-we-are.module.scss"
import WhoWeAreSvg from "../../images/who-we-are.svg"

const WhoWeAre = () =>{
    return (
        <div className={CS.whoWe}>
            <div className={CS.row}>
                <div className={CS.whoWeImage}>
                    <img src={WhoWeAreSvg} alt="Who We Are" />
                </div>

                <div className={CS.whoWeContent}>
                    <p className={CS.smallTitle}>
                        Who we are
                    </p>
                    <p>
                        We are an on-line ghost writing company with over 200 writers on our team.
                        We can tackle almost any academic, professional content or any other written assignment.
                    </p>
                    <p>
                        Custom essay, term papers and research papers can be done in as little as 3 hours.
                    </p>
                    <p className={CS.smallText}>
                        Our writers work in different fields of study and have years of professional term paper writing and essay writing experience under their belts.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default WhoWeAre