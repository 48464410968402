import React, { useState } from "react"
import AliceCarousel from 'react-alice-carousel';
import SliderPager from "../SliderPager";
import * as CS from "../../styles/components/_services-slider.module.scss"
import services from '../../content/landingPosts.json'

const TheServicesSlider = () => {
    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        620: { items: 2 },
        992: {items: 3},
        1024: { items: 3 },
        1200: { items: 3 },
    }
    const [counter, setCounter] = useState(0)

    const items = services.slice(0, 15).map((item) => {
        return (
            <a key={item.id} href={item.url} className={CS.servicesCarouselItem}>
                <div className={CS.servicesCarouselItemContent}>
                    <p className={CS.servicesCarouselItemTitle}>
                        { item.post_title_primary }
                    </p>
                    <p className={CS.servicesCarouselItemDescr}>
                        { item.post_meta_description }
                    </p>
                </div>
            </a>
        )
    })

    return (
        <div className={`${CS.servicesSlider} wrapper`}>
            <div className={CS.servicesSliderCenter}>
                <p className={CS.servicesSliderTitle}>
                    Most types of writing projects are handled with ease
                </p>
                <p>
                    We assist with all types of academic and professional written assignments.
                    We have over 200 professional writers on our team so we always have people on-line round-the-clock.
                </p>
            </div>

            <div className={`${CS.servicesCarousel} slider`}>
                <AliceCarousel
                    mouseTracking
                    items={items}
                    onSlideChanged={(e) => setCounter(e.item)}
                    activeIndex={counter}
                    responsive={responsive}
                    infinite controlsStrategy="alternate"
                />
            </div>
            <SliderPager count={counter + 1} all={items.length} />
            <div className={CS.buttonWrapper}>
                <a
                    href="/writing-services"
                    className="button button-secondary"
                >
                    All services
                </a>
            </div>
        </div>
    )
}

export default TheServicesSlider
