import React from "react"
import * as CS from "../../styles/components/_why-order-from-us.module.scss"
import Candle from "../../images/candle.svg"
import Reward from "../../images/reward.svg"
import Discount from "../../images/discount.svg"
import Lock from "../../images/lock.svg"

const WhyOrderFromUs = () => {
    return (
        <section className={CS.services}>
            <div className="wrapper">
                <div className={CS.servicesHeader}>
                    <h2>
                        Why order from us?
                    </h2>
                    <p>
                        We don't just sell you essays. We provide a service that is built on principles!
                    </p>
                </div>

                <div className={CS.row}>
                    <div className={CS.servicesCard}>
                        <img src={Candle} alt="candle" className={CS.servicesCardIcon} />
                        <h3 className={CS.servicesCardTitle}>
                            Great Writers
                        </h3>
                        <p className={CS.servicesCardText}>
                            Our stringent hiring policies and the years in the business have helped us build our dream team of essay writers!
                        </p>
                    </div>
                    <div className={CS.servicesCard}>
                        <span>
                            <img src={Reward} alt="reward" className={CS.servicesCardIcon} />
                        </span>
                        <h3 className={CS.servicesCardTitle}>
                            No Plagiarism
                        </h3>
                        <p className={CS.servicesCardText}>
                            Here, the writers that ever plagiarized were always fired immediately and lost their money.
                        </p>
                    </div>
                    <div className={CS.servicesCard}>
                        <img src={Discount} alt="discount" className={CS.servicesCardIcon} />
                        <h3 className={CS.servicesCardTitle}>
                            Fair Price
                        </h3>
                        <p className={CS.servicesCardText}>
                            We always charge a fair price for the kind of service we deliver.
                        </p>
                    </div>
                    <div className={CS.servicesCard}>
                        <img src={Lock} alt="lock" className={CS.servicesCardIcon} />
                        <h3 className={CS.servicesCardTitle}>
                            Confidentiality
                        </h3>
                        <p className={CS.servicesCardText}>
                            No third-party will ever find out you've used our service. Our company takes our clients' privacy very seriously!
                        </p>
                    </div>
                </div>
                {/* <div className="services__buttons">
                    <a
                        href="/"
                        className="btn-base btn-base_normal btn-base_border"
                        onClick={() => scrollTo(selector)}
                    >
                        how we work
                    </a>
                </div> */}
            </div>
        </section>
    )
}

export default WhyOrderFromUs