import React from "react"

const SliderPager = (props) => {
    return (
        <div className="slider-pager">
            <span className="slider-pager__count">{props.count}</span>
            <span className="slider-pager__all">of {props.all}</span>
        </div>
    )
}

export default SliderPager


