// extracted by mini-css-extract-plugin
export var buttonWrapper = "_services-slider-module--button-wrapper--9cd24";
export var servicesCarousel = "_services-slider-module--services-carousel--f6260";
export var servicesCarouselItem = "_services-slider-module--services-carousel-item--a7dea";
export var servicesCarouselItemContent = "_services-slider-module--services-carousel-item-content--c994d";
export var servicesCarouselItemDescr = "_services-slider-module--services-carousel-item-descr--09b52";
export var servicesCarouselItemImg = "_services-slider-module--services-carousel-item-img--e7a46";
export var servicesCarouselItemTitle = "_services-slider-module--services-carousel-item-title--1ff30";
export var servicesCarouselSlider = "_services-slider-module--services-carousel-slider--52684";
export var servicesCarousel_itemTitle = "_services-slider-module--services-carousel_item-title--5388f";
export var servicesSlider = "_services-slider-module--services-slider--ccd23";
export var servicesSliderCenter = "_services-slider-module--services-slider-center--fa2df";
export var servicesSliderTitle = "_services-slider-module--services-slider-title--b2136";